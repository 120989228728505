import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main, containers } from '../../content/palety'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import alt from '../../content/alt'
import { s, sTextGradient, sDesc } from '../../style'

const Palety = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/palety.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      image0: file(absolutePath: { regex: "/images/palletes/image0.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image1: file(absolutePath: { regex: "/images/palletes/image1.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image2: file(absolutePath: { regex: "/images/palletes/image2.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image3: file(absolutePath: { regex: "/images/palletes/image3.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image4: file(absolutePath: { regex: "/images/palletes/image4.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image5: file(absolutePath: { regex: "/images/palletes/image5.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image6: file(absolutePath: { regex: "/images/palletes/image6.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image7: file(absolutePath: { regex: "/images/palletes/image7.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image8: file(absolutePath: { regex: "/images/palletes/image8.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image9: file(absolutePath: { regex: "/images/palletes/image9.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      image10: file(absolutePath: { regex: "/images/palletes/image10.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
      ua: 'ПЕРЕГЛЯНУТИ',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='bottom'
      />
      <Main h={1} title={main.title[lang]} body={main.desc[lang]}>
        {containers.map(({ title, items }, id) => (
          <div key={id} css={sOuter}>
            <h2 css={sTitle}>{title[lang]}</h2>
            {items.map(({ text, images }, id) => (
              <div key={id} css={sItem}>
                <p css={sText}>{text[lang]}</p>
                {images.map((image) => (
                  <GatsbyImage
                    image={getImage(query[image].childImageSharp)}
                    alt={intro.title[lang] + alt}
                    css={{
                      marginBottom: '2rem',
                      width: query[image].childImageSharp.gatsbyImageData.width,
                      [s.md_down]: { maxWidth: 'calc(100vw - 8rem)' },
                      [s.sm_down]: { maxWidth: 'calc(100vw - 4rem)' },
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        ))}
      </Main>
    </Layout>
  )
}

const sOuter = {
  marginTop: '8rem',
  [s.sm_down]: {
    marginTop: '4rem',
  },
}

const sItem = {
  display: 'flex',
  flexDirection: 'column',
}

const sTitle = {
  ...sTextGradient,
  fontSize: '3rem',
  marginBottom: '2rem',
  [s.sm_down]: {
    fontSize: '2rem',
  },
}

const sText = {
  ...sDesc,
  marginBottom: '2rem',
}

export default Palety
