export const seo = {
  url: 'klient/palety',
  title: {
    pl: 'Rozmieszczenie palet w kontenerze',
    en: 'Arrangement of pallets in container',
    ua: 'Способи розміщення піддонів у морських контейнерах',
  },
  desc: {
    pl: `Optymalne rozmieszczenie palet w kontenerze to kluczowy element planowania załadunku towarów.`,
    en: `The optimal arrangement of the pallets in the container is a key element in planning the loading of goods.`,
    ua: `Оптимальне розташування піддонів у контейнері є ключовим елементом планування завантаження вантажів.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'palety',
    'kontener',
    'rozmieszczenie',
  ],
}

export const intro = {
  title: {
    pl: 'Rozmieszczenie Palet',
    en: 'Pallets Arrangement',
    ua: 'Розміщення піддонів',
  },
  desc: {
    pl: `Optymalne rozmieszczenie palet w kontenerze to kluczowy element planowania załadunku towarów.`,
    en: `The optimal arrangement of the pallets in the container is a key element in planning the loading of goods.`,
    ua: `Оптимальне розташування піддонів у контейнері є ключовим елементом планування завантаження вантажів.`,
  },
}

export const main = {
  title: {
    pl: 'Sposoby rozmieszczenia palet w kontenerach morskich',
    en: 'Arrangement of pallets in particular types of containers',
    ua: 'Способи розміщення піддонів у морських контейнерах',
  },
  desc: {
    pl: `
    <p>
    <strong>Aby ułatwić planowanie transportu z użyciem kontenerów</strong>, stosuje się odpowiednią logistykę dotyczącą ułożenia palet.
    </p>
    <p>
    Głównym warunkiem optymalnego sposobu umiejscowienia ładunków wewnątrz kontenera jest to, aby zmieścić jak największą liczbę palet przy jednoczesnym ułożeniu ich w taki sposób, aby partie nie zostały uszkodzone, a maksymalny ciężar użytkowy nie został przekroczony.
    </p>
    <p>
    Ograniczenia wagowe w transporcie drogowym na terenie Polski to, w zależności od typu kontenera, 21 lub 22 tony.
    </p>
    <p>
    Poniżej znajdują się grafiki przedstawiające ułożenie palet w poszczególnych typach kontenerów.
    </p>
    `,
    en: `
    <p>
    <strong>In order to facilitate the planning of transport using containers</strong>, appropriate logistics for palletising are applied.
    </p>
    <p>
    The main prerequisite for optimal arrangement of the loads inside a container is that the maximum number of pallets can be accommodated, while at the same time arranging them in such a way that the batches are not damaged, and the maximum payload is not exceeded.
    </p>
    <p>
    Weight limitations for road transport in Poland are, depending on the type of container, 21 or 22 tons.
    </p>
    <p>
    Below you will find graphics showing the arrangement of pallets in particular types of containers.
    </p>
    `,
    ua: `
    <p>
    <strong>Для полегшення планування транспортування з використанням контейнерів використовується відповідна логістика для штабелювання палет.</strong>
    </p>
    <p>
    Основна умова оптимального розміщення вантажів всередині контейнера - вмістити якомога більше піддонів, розташувавши їх таким чином, щоб партії не пошкодилися і не перевищили максимальне корисне навантаження.
    </p>
    <p>
    Вагові обмеження в автомобільному транспорті в Польщі становлять, залежно від типу контейнера, 21 або 22 тонни.
    </p>
    <p>
    Нижче наведені малюнки, що демонструють розташування палет в окремих типах контейнерів.
    </p>
    `,
  },
}

export const containers = [
  {
    title: {
      pl: `KONTENER 20'DV`,
      en: `CONTAINER 20'DV`,
      ua: `КОНТЕЙНЕР 20'DV`,
    },
    items: [
      {
        text: {
          pl: '11 europalet 120 x 80 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `11 euro-pallets 120 x 80 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `11 європіддонів 120 х 80 [см] в один шар (для товарів, що не виступають за контури піддону)`,
        },
        images: ['image0'],
      },
      {
        text: {
          pl: '9-10 palet przemysłowych 120 x 100 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `9-10 euro-pallets 120 x 100 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `9-10 промислових піддонів 120 х 100 [см] в один шар (для товарів, які не виступають за контури піддону)`,
        },
        images: ['image1', 'image2'],
      },
    ],
  },
  {
    title: {
      pl: `KONTENER 20'RF - REEFER (CHŁODNIA)`,
      en: `CONTAINER 20'RF - REEFER (FREEZER)`,
      ua: `КОНТЕЙНЕР 20'RF - REEFER (ХОЛОДНЕ ЗБЕРІГАННЯ)`,
    },
    items: [
      {
        text: {
          pl: '10 europalet 120 x 80 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `10 euro-pallets 120 x 80 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `10 європіддонів 120 х 80 [см] в один шар (для товарів, які не виступають за контури піддону)`,
        },
        images: ['image3'],
      },
      {
        text: {
          pl: '9 palet przemysłowych 120 x 100 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `9 euro-pallets 120 x 100 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `9 промислових піддонів 120 х 100 [см] в один шар (для товарів, які не виступають за контури піддону)`,
        },
        images: ['image4'],
      },
    ],
  },
  {
    title: {
      pl: `KONTENER 40'DV`,
      en: `CONTAINER 40'DV`,
      ua: `КОНТЕЙНЕР 40'DV`,
    },
    items: [
      {
        text: {
          pl: '24-25 europalet 120 x 80 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `24-25 euro-pallets 120 x 80 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `24-25 європіддонів 120 х 80 [см] в один шар (для товарів, що не виступають за контури піддону)`,
        },
        images: ['image5', 'image6'],
      },
      {
        text: {
          pl: '20-21 palet przemysłowych 120 x 100 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `20-21 euro-pallets 120 x 100 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `20-21 промислових піддонів 120 х 100 [см] в один шар (для товарів, які не виступають за контури піддону)`,
        },
        images: ['image7', 'image8'],
      },
    ],
  },
  {
    title: {
      pl: `KONTENER 40'RF - REEFER (CHŁODNIA)`,
      en: `CONTAINER 40'RF - REEFER (FREEZER)`,
      ua: `КОНТЕЙНЕР 40'RF - REEFER (ХОЛОДНЕ ЗБЕРІГАННЯ)`,
    },
    items: [
      {
        text: {
          pl: '23 europalety 120 x 80 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `23 euro-pallets 120 x 80 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `23 європіддони 120 х 80 [см] в один шар (для товарів, що не виступають за контури піддону)`,
        },
        images: ['image9'],
      },
      {
        text: {
          pl: '20 palet przemysłowych 120 x 100 [cm] w jednej warstwie (dla towaru niewystającego poza obrys palety)',
          en: `20 euro-pallets 120 x 100 [cm] in one layer (for goods not protruding beyond the pallet's outline)`,
          ua: `20 промислових піддонів 120 х 100 [см] в один шар (для товарів, які не виступають за контури піддону)`,
        },
        images: ['image10'],
      },
    ],
  },
]
